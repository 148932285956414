import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='p-4 py-8 sm:p-8 md:p-16 xl:px-32 footer text-gray-50'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 '>
        <div>
          <Link to='/' className='text-lg block mb-4 font-extrabold'>
            First Horizon Trust
          </Link>
          <p
            py={2}
            mb={4}
            sx={{
              fontFamily: 'inherit',
              color: 'var(--primary)',
              fontWeight: 300,
              fontSize: { xs: '1rem', sm: '1rem', md: '1.1rem' },
            }}
          >
            Thousands of customer worldwide trust us for their business and
            personal needs. Start banking with us today.
          </p>
        </div>
        {/* . . . */}
        <div>
          <p className='mb-4 text-lg sm:text-xl font-extrabold text-primary'>
            Quick Links
          </p>
          <div className='flex flex-col mb-3 gap-4'>
            <Link to='/'>Home</Link>
            <Link to='/about'>About us</Link>
            <Link to='/contact'>Contact</Link>
          </div>
        </div>
        {/* . . . */}
        <div>
          <p className='mb-4 text-lg sm:text-xl font-extrabold text-primary'>
            Contact Us
          </p>
          <div
            sx={{
              '& p': {
                marginBottom: '0.75rem',
                fontFamily: 'inherit',
                fontWeight: 300,
              },
            }}
          >
            <p>@firsthorizontrust</p>
            <p>support@firsthorizontrust.online</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
