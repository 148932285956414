import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const links = [
  { id: 'home', name: 'home', url: '/' },
  { id: 'about', name: 'about', url: '/about' },
  { id: 'contact', name: 'contact us', url: '/contact' },
];

const Navbar = () => {
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      setScrollHeight(window.scrollY);
    });
  }, []);

  return (
    <nav
      className={`w-full flex items-center justify-between gap-8 fixed top-0 left-0 z-20 p-8 sm:px-16 lg:px-24 duration-500  ${
        scrollHeight > 100
          ? 'bg-white text-gray-800'
          : 'bg-transparent text-white'
      }`}
    >
      <Link to='/' className='text-xl font-extrabold'>
        First Horizon Trust
      </Link>

      <ul className='items-center justify-evenly gap-8 font-semibold hidden md:flex'>
        {links.map((link) => (
          <Link key={link.id} to={link.url} className='p-2 uppercase text-sm'>
            {link.name}
          </Link>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
