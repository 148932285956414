import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase.config';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formdata, setFormdata] = useState({ email: '' });

  const { email } = formdata;

  const inputChangeHandler = (e) => {
    setFormdata((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const passwordResetHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const res = await sendPasswordResetEmail(auth, email);

      console.log(res);

      toast.success(
        'A link to reset your password has been sent to your email.'
      );

      setIsLoading(false);

      navigate('/login');
    } catch (err) {
      toast.err('Cannot process request at this time.');

      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className='padding py-16 min-h-screen bg-gray-900 flex flex-col gap-8 items-center justify-center'>
        <Link
          to='/'
          className='font-extrabold text-gray-100 text-xl text-center'
        >
          First Horizon Trust
        </Link>
        <div className='bg-white shadow-md rounded-md p-6 w-full max-w-lg'>
          <div className='mb-4'>
            <p className='text-gray-700 font-extrabold text-2xl'>
              Reset Password
            </p>
          </div>
          <form className='gap-6 py-4' onSubmit={passwordResetHandler}>
            <div className='w-full mb-4'>
              <label
                htmlFor='email'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Email Address *
              </label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                value={email}
                onChange={inputChangeHandler}
              />
            </div>

            <button
              type='submit'
              className={`btn ${
                isLoading ? 'bg-gray-600' : 'bg-primary hover:bg-secondary'
              } text-center mt-8 py-4 text-white  w-full`}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Reset Password'}
            </button>
          </form>

          <p className='mt-4 text-gray-700 text-center text-sm'>
            Don`t have an account?{' '}
            <Link to='/register' className='text-primary font-semibold'>
              Register
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
